<template>
  <div>
    <transition name="fade">
      <div v-if="isShow" class="fade" @mousedown.self="close('by_fade')"></div>
    </transition>
    <div class="share_slider_wrap" :class="{active: isShow}" @mousedown.self="close('by_fade')">
      <div class="share_slider container">
        <div class="title mb">
          <div class="text title1">{{$t('ShareSlider.share')}}</div>
          <div class="close" @click="close"><img src="@/assets/images/close.svg"></div>
        </div>

        <div class="share_item">
          <div class="text">{{$t('ShareSlider.facebook')}}</div>
          <div class="icon facebook"></div>
        </div>

        <div class="share_item">
          <div class="text">{{$t('ShareSlider.whatsapp')}}</div>
          <div class="icon whatsapp"></div>
        </div>

        <div class="share_item">
          <div class="text">{{$t('ShareSlider.mail')}}</div>
          <div class="icon mail"></div>
        </div>

        <div class="share_item">
          <div class="text">{{$t('ShareSlider.copy_link')}}</div>
          <div class="icon copy"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ShareSlider',
    props: {
      isShow: {
        type: Boolean,
        required: true,
      },
      callback: {
        type: Function,
      }
    },
    methods: {
      close(data) {
        this.callback(data);
      }
    },
    watch: {
      // хак, для того чтобы нельзя было скролить body при открытом модальном окне
      // eslint-disable-next-line no-unused-vars
      isShow(newValue, oldValue) {
        const body = document.getElementsByTagName('body')[0];
        if (newValue) {
          body.classList.add('modal-open');
        } else {
          body.classList.remove('modal-open');
        }
      }
    },
  }
</script>

<style>
  body.modal-open {
    overflow: hidden;
  }
</style>

<style scoped lang="scss">
  .fade {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
  }

  .share_slider_wrap {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0px;
    overflow: hidden;
    transition: height 0.5s ease;
  }

  .share_slider {
    background: rgb(255, 255, 255);
    min-height: 100px;
    border-radius: 8px 8px 0px 0px;
    padding-top: 16px;
  }

  .share_slider_wrap.active {
    height: 320px;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.2s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .title {
    display: flex;
    .text {
      flex-grow: 1;
    }
  }

  .share_item {
    display: flex;
    align-items: center;
    height: 48px;
    background: #F5F8FF;
    border-radius: 4px;
    padding: 2px 12px;
    margin-bottom: 8px;
    .text {
      flex-grow: 1;
      font-size: 16px;
      color: #383838;
    }
    .icon {
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: contain;
    }
    .facebook {
      background-image: url("~@/assets/images/share/facebook.png");
    }
    .whatsapp {
      background-image: url("~@/assets/images/share/whatsapp.png");
    }
    .mail {
      background-image: url("~@/assets/images/share/mail.png");
    }
    .copy {
      background-image: url("~@/assets/images/share/copy.png");
    }
  }
</style>
