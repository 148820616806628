<template>
  <div class="counters">
    <div class="column">
      <div class="name">{{$t('Counters.invites_sent')}}</div>
      <div class="value">4</div>
    </div>
    <div class="column">
      <div class="name">{{$t('Counters.enrolled_friends')}}</div>
      <div class="value">5</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Counters'
  };
</script>

<style scoped lang="scss">
  .counters {
    display: flex;
    // justify-content: space-around;

    .column {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
    }

    .name, .value {
      padding: 2px;
    }

    .name {
      font-family: Rubik;
      font-size: 12px;
      color: #7D7D7D;
    }

    .value {
      font-family: Rubik;
      font-weight: 500;
      font-size: 24px;
      color: #383838;
    }
  }

</style>
