<route>
{
  "name": "profile",
  "meta": {
    "title": "Profile"
  }
}
</route>

<template>
  <div class="onboarding_flex flex-column">
    <div class="title1 mb">{{$t('index.hi_christina')}} <span class="hi_icon"></span></div>
    <div class="onboarding_content_nc">
      <div class="center">
        <img src="@/assets/images/art-home_2x.png" width="268" height="220">
      </div>
      <div class="text1 mb-8">{{$t('index.referral_program')}}</div>

      <div class="title3 mb">{{$t('index.invite_friends')}}</div>
      <div class="text3 mb">
        {{$t('index.bring_your_nurse_friend_to')}}
      </div>

      <copy-link class="mb"></copy-link>
      <counters></counters>
    </div>

    <div class="onboarding_content">

    </div>

    <div class="onboarding_footer">
      <ui-button @click="share">{{$t('index.share_my_invite_link')}}</ui-button>
    </div>

    <ShareSlider :isShow="isShare" :callback="modalClose"/>

  </div>
</template>

<script>
  import CopyLink from '../../components/profile/CopyLink';
  import Counters from '../../components/profile/Counters';
  import UiButton from '../../components/ui/UiButton';
  import ShareSlider from '../../components/profile/ShareSlider';

  export default {
    name: 'index',
    components: {
      ShareSlider,
      UiButton,
      Counters,
      CopyLink
    },
    data() {
      return {
        isShare: false,
      }
    },
    methods: {
      modalClose() {
        this.isShare = false;
      },
      share() {
        this.isShare = true
      }
    }
  }
</script>

<style scoped lang="scss">
  .onboarding_flex {
    display: flex;
    flex-direction: column;

    .onboarding_wrap {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin-bottom: -20px;
    }

    .onboarding_content_nc {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 32px 0px;
    }

    .onboarding_icon {
      height: 120px;
    }

    .onboarding_footer {
      height: 100px;
      .link {
        margin-top: 20px;
      }
    }

    .fixed_footer {
      position: fixed;
      left: 0px;
      right: 0px;
      bottom: 0px;
      height: 160px;
      background: linear-gradient(to bottom,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 20%,
        rgba(255, 255, 255, 1) 100%
      );
    }

    .mb-fixed-footer {
      margin-bottom: 120px;
    }

    div {
      // outline: 1px dashed red;
    }

  }

  .hi_icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url("~@/assets/images/hi.png");
    background-repeat: no-repeat;
  }
</style>
