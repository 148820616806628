<template>
  <div>
    <div class="copy_link" @click="copy(url)">
      {{url}}
    </div>
    <div v-if="isCopied" class="helper">{{$t('CopyLink.link_copied_just_paste_and')}}</div>
  </div>
</template>

<script>
  export default {
    name: 'CopyLink',
    data() {
      return {
        url: 'https://GermanLanguage/invite452',
        isCopied: false,
      }
    },
    methods: {
      copy(str) {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        this.isCopied = true;
      }
    }
  };
</script>

<style scoped>
.copy_link {
  background: #FAFAFA;
  border-radius: 4px;
  padding:12px;
  cursor:pointer;
}
  .helper {
    font-family: Rubik;
    font-size: 14px;
    color: #59CD90;
  }
</style>
